import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(tel: string) {

    let country: string, dd: string, part1: string, part2: string;

    if(tel != null){
      country = tel.slice(0, 2);
      dd = tel.slice(2, 4);
      part1 = tel.slice(4, 9);
      part2 = tel.slice(9, 13);
    }
    
    return '(' + dd + ') ' + part1 + '-' + part2;
  }
}
