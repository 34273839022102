import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// import { MainService } from '../_services/main.service';
import { GlobalsService } from '../services/globals.service';

@Injectable()
export class CallInterceptor implements HttpInterceptor {

  constructor(
    // private main: MainService,
    private globals: GlobalsService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let user = this.globals.userStorage();
    return next.handle(request).pipe(tap(resp => {
      if (resp['headers'] && resp['headers'].has('jwt')) {
        user.j = resp['headers'].get('jwt');
        localStorage.u = JSON.stringify(user);
      }
    }));
  }

}
