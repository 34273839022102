import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BaseRequestBody } from 'src/app/_shared/models/auth.model';
import { GlobalsService } from 'src/app/_shared/services/globals.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationWebportalsService {

  constructor(
    private http: HttpClient,
    private globals: GlobalsService
  ) { }

  public optin(){
   
    return this.http.post<any>(`${this.globals.relativeUrl}/webportals-api/optin`, {...this.globals.requestPreset() }, { headers: this.globals.callHeaders(false) });
  }

  public doLogin(params: BaseRequestBody) {
    return this.http.post<any>(`${this.globals.relativeUrl}/webportals-api/account/login`, { ...params, ...this.globals.requestPreset() }, { headers: this.globals.callHeaders() }).pipe(map(res => {
      return this.globals.mapResponse(res);
    }));
  }

  public doPinCheck(params: BaseRequestBody) {
    return this.http.post<any>(`${this.globals.relativeUrl}/webportals-api/account/login/confirm`, { ...params, ...this.globals.requestPreset() }, { headers: this.globals.callHeaders() }).pipe(map(res => {
      return this.globals.mapResponse(res);
    }));
  }
}
