import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  public getItem(key: string): any {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch {
      this.removeItem(key);

      return null;
    }
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  public setItem(key: string, value: any): boolean {
    try {
      localStorage.setItem(key, JSON.stringify(value));

      return true;
    } catch {
      return false;
    }
  }

  public clearAll(): void {
    localStorage.clear();
  }
}


export class SessionStorageService {

  public getItem(key: string): any {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch {
      this.removeItem(key);

      return null;
    }
  }

  public getSessionItem(key: string): any {
    try {
      return JSON.parse(sessionStorage.getItem(key));
    } catch {
      this.removeItem(key);

      return null;
    }
  }

  public removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  public setItem(key: string, value: any): boolean {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));

      return true;
    } catch {
      return false;
    }
  }

  public setSessionItem(key: string, value: any): boolean {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));

      return true;
    } catch {
      return false;
    }
  }

  public clearAll(): void {
    localStorage.clear();
  }
}