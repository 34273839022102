import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalsService } from './globals.service';
import { LocalStorageService } from './_storage/storage.service';
import { LOCAL } from '../storage/local.enum';

@Injectable({
  providedIn: 'root',
})
export class LangService {

  constructor(
    private readonly translateService: TranslateService,
    private readonly localStorage: LocalStorageService,
    private readonly global: GlobalsService

  ) {

    const userLang = navigator.language;
    let defaultLang = this.global.lang;

    if (this.global.multiLang) {
      if (this.global.langsList.includes(userLang)) {
        defaultLang = userLang;
      }
    }

    this.translateService.setDefaultLang(defaultLang);
    this.translateService.use(defaultLang);
    this.localStorage.setItem(LOCAL.LANG, defaultLang);

  }

  public get currentLang() {
    return this.localStorage.getItem(LOCAL.LANG)
  }

  changeLang(lang) {
    this.localStorage.setItem(LOCAL.LANG, lang);
    this.translateService.use(lang);
  }
}
