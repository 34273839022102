import { Component } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  RouterEvent,
  NavigationError,
  Event,
  ActivatedRoute,
} from '@angular/router';
import { filter, finalize } from 'rxjs/operators';
import { BehSubjectService } from './_shared/services/beh-subject.service';
import { GlobalsService } from './_shared/services/globals.service';
import { EventsService } from './_shared/services/events.service';
import { LangService } from './_shared/services/lang.service';
import { routerTransition } from './_shared/interceptors/router.animations';
import { User } from './_shared/models/user.model';
import { AuthenticationService } from './authentication/services/authentication.service';
import { LocalStorageService } from './_shared/services/_storage/storage.service';
import { LOCAL } from './_shared/storage/local.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition],
})
export class AppComponent {
  loader$ = false;
  currentUser: User;

  constructor(
    private router: Router,
    private bSubject: BehSubjectService,
    private globals: GlobalsService,
    private events: EventsService,
    private lang: LangService,
    private route: ActivatedRoute,
    private beh: BehSubjectService,
    private authenticationService: AuthenticationService,
    private localStorage: LocalStorageService
  ) {

    this.events.eventStart({ eventType: 'APP_INIT' });

    this.authenticationService.getHE().subscribe(
      res => {
        if (res.msisdn) {
          this.loginHE(res.msisdn)
        }
      }
    )

    this.route.queryParams.pipe(
      finalize(() =>{
        
        this.bSubject.loader$.subscribe((val) => {
        this.loader$ = val;
    });
      })
    )
    .subscribe(params => {

        if (params['urlid']) {
          this.beh.urlid$.next(params['urlid']);
        }

      if (params['mec']) {
        this.loginMEC(params['mec'], this.router.url.split("?")[0]);
      } 
    });

    router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((e) => {
        // ----- REGISTO DE EVENTOS -----
        
      });
  }

  loginMEC(mec, url) {
    // debugger;
    this.authenticationService.decryptMEC(mec).subscribe(
      res => {

        this.localStorage.setItem(LOCAL.CURRENT_USER, btoa(res['result']))
        this.localStorage.setItem(LOCAL.TRAFFIC_TYPE, 3)
        let u = {
          msisdn: res['result']
        }

        this.events.eventStart({
          eventType: 'LOGIN_SUCCESS',
          msisdn: res['result'],
          triggerType: 'response',
          trafficTypeId: 3,
          trafficTypeName: 'FORWARD'
        });

        this.authenticationService.currentUserSubject.next(u);
        this.globals.initPush(res['result']);

        // const urlToUse = url ? url : '/home/cupons/free'
        const urlToUse = url ? url : '/home/scratch'

        // debugger;
        
        this.router.navigate([urlToUse]);
      }
      );
    }
  loginHE(msisdn) {
        
        this.localStorage.setItem(LOCAL.CURRENT_USER, btoa(msisdn))
        this.localStorage.setItem(LOCAL.TRAFFIC_TYPE, 1)
        let u = {
          msisdn: msisdn
        }

        this.events.eventStart({
          eventType: 'LOGIN_SUCCESS',
          msisdn: msisdn,
          triggerType: 'response',
          trafficTypeId: 1,
          trafficTypeName: '3G'
        });

        this.authenticationService.currentUserSubject.next(u);
        this.globals.initPush(msisdn);

        let url = this.router.url.split("?")[0];

        // const urlToUse = url ? url : '/home/cupons/free'
        const urlToUse = url ? url : '/home/scratch'

        this.router.navigate([urlToUse]);
    }

  ngOnInit() {
    let msisdn = this.authenticationService.currentUserValue ? atob(this.authenticationService.currentUserValue.toString()) : null;
    this.globals.initPush(msisdn);
  }

  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.loader$ = true;
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      RouterEvent instanceof NavigationError
    ) {
      this.loader$ = false;
    }
  }
}
