import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, CanActivateChild, ActivatedRoute } from '@angular/router';
// import { storage } from 'firebase';
import { LoginComponent } from 'src/app/authentication/login/login.component';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { BehSubjectService } from '../services/beh-subject.service';
import { EventsService } from '../services/events.service';
import { GlobalsService } from '../services/globals.service';
import { LocalStorageService } from '../services/_storage/storage.service';
import { LOCAL } from '../storage/local.enum';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private route: Router,
    private authenticationService: AuthenticationService,
    private localStorage: LocalStorageService,
    private beh: BehSubjectService,
    private events: EventsService,
    private globals: GlobalsService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {   

    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      return true;
    }
    

    // route.queryParams.subscribe(params => {
      
      if (route.queryParams['mec']) {
        this.loginMEC(route.queryParams['mec'], state.url.split("?")[0]);
      } else {
        this.route.navigate(['/login']);
      }
      if (route.queryParams['urlid']) {
        this.beh.urlid$.next(route.queryParams['urlid']);
      }
    // });

    // this.route.navigate(['/login']);

    // not logged in so redirect to login page with the return url
    
    return false;
  }

  // public canActivateChild(): boolean {
  //   return this.canActivate();
  // }

  // public canLoad(): boolean {
  //   return this.canActivate();
  // }

  loginMEC(mec, url) {
    this.authenticationService.decryptMEC(mec).subscribe(
      res => {

        this.localStorage.setItem(LOCAL.CURRENT_USER, btoa(res['result']))
        this.localStorage.setItem(LOCAL.TRAFFIC_TYPE, 3)
        let u = {
          msisdn: res['result']
        }

        this.events.eventStart({
          eventType: 'LOGIN_SUCCESS',
          msisdn: res['result'],
          triggerType: 'response',
          trafficTypeId: 3,
          trafficTypeName: 'FORWARD'
        });

        this.authenticationService.currentUserSubject.next(u);
        // this.globals.initPush(res['result']);

        // console.log(this.router.url);

        // const urlToUse = url ? url : '/home/cupons/free'
        const urlToUse = url ? url : '/home//home/scratch'
        
        this.route.navigate([urlToUse]);
      }
      );
    }
}
