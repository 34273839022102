import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchDraw'
})
export class SearchDrawPipe implements PipeTransform {

  transform(value: any, type: any, date: any): any {
    // debugger
        if (!value) return null;
        if(!type && !date) return value;
    
        if(type && !date){
    
          type = type.toLowerCase();
          return value.filter(function (item) {
            return JSON.stringify(item).toLowerCase().includes(type);
          });
    
        } else if(date && !type){
    
          date = date.toLowerCase();
          return value.filter(function (item) {
            return JSON.stringify(item).toLowerCase().includes(date);
          });
    
        } else {
    
          type = type.toLowerCase();
          date = date.toLowerCase();
    
          let auxValue: any[] = [];
          value.filter(function (item) {
            if(JSON.stringify(item).toLowerCase().includes(type)){
              auxValue.push(item);
            }
          });
          auxValue.filter(function (item) {
            return JSON.stringify(item).toLowerCase().includes(date);
          });
        }
      }

}
