import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, ObservedValueOf, throwError } from 'rxjs';
import { catchError, map, pluck, tap } from 'rxjs/operators';
import { GlobalsService } from 'src/app/_shared/services/globals.service';
import { LocalStorageService } from 'src/app/_shared/services/_storage/storage.service';
import { LOCAL } from 'src/app/_shared/storage/local.enum';
import { User } from 'src/app/_shared/models/user.model';
import { LangService } from 'src/app/_shared/services/lang.service';
import { BehSubjectService } from 'src/app/_shared/services/beh-subject.service';
import { EventsService } from 'src/app/_shared/services/events.service';
import { Account } from 'src/app/_shared/models/auth.model';
import { AuthenticationWebportalsService } from './authentication-webportals.service';



@Injectable({ providedIn: 'root' })

export class AuthenticationService {

  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private globals: GlobalsService,
    public router: Router,
    public localStorage: LocalStorageService,
    public lang: LangService,
    private beh: BehSubjectService,
    private events: EventsService,
    private authTwt: AuthenticationWebportalsService
  ) {

    this.currentUserSubject = new BehaviorSubject<User>(this.localStorage.getItem(LOCAL.CURRENT_USER));
    this.currentUser = this.currentUserSubject.asObservable();

  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  

  //----- SEND A NEW PIN -----
  public resetPin(msisdn: string) {
    const callHeader = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'action': 'resetpin',
      'user-msisdn': msisdn
    };

    this.beh.apiRequest$.next(JSON.stringify({ header: callHeader, body: null }));
    const httpOptions = { headers: new HttpHeaders(callHeader) };

    return this.http.get<any>(`${this.globals.relativeUrl}/api/middle.php`, httpOptions);
  }

  //----- VERIFY IF PIN IS CORRECT -----
  public validatePin(msisdn: string, pin) {
    const callHeader = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'action': 'validatepin',
      'user-msisdn': msisdn,
      'pin': JSON.stringify(pin)
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'user-msisdn': msisdn,
        'pin': pin,
        'action': 'validatepin',
        'Access-Control-Allow-Origin': '*'
      })
    };

    this.beh.apiRequest$.next(JSON.stringify({ header: httpOptions, body: null }));


    return this.http.get<any>(`${this.globals.relativeUrl}/api/middle.php`, httpOptions).pipe(
      tap((response: any) => {
        if (response && response.respStatus == 1) {
          this.localStorage.setItem(LOCAL.CURRENT_USER, btoa(msisdn))
          this.currentUserSubject.next(response);
         }
     }),
    );
  }

  decryptMEC(mec) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
    }) };
    let encodedToken = encodeURIComponent(mec.replace(/ /g, '+'));
    
    const url = `/token/decrypt?serviceId=${this.globals.serviceId}&data=${encodedToken}`;

    return  this.http.get<any[]>(url).pipe(pluck('responseData'));
  }

  normalizeMsisdn(msisdn): Observable<any> {

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const body = {
      msisdn: msisdn,
      operatorId: this.globals.operatorId,
    };

    const url = `${this.globals.relativeUrl}/msisdn-normalize`;

    this.beh.apiRequest$.next(
      JSON.stringify({ body: body, header: httpOptions, endpoint: url })
    );

    return this.http.post(`${url}`, body, httpOptions).pipe(
      pluck('responseData')
    )
  }

  getHE(): Observable<any> {

    const url = `${this.globals.relativeUrl}/headers`;
    
    const endPoint = `${url}`;

    return this.http.get(`${endPoint}`);


    // return this.http.get<any>(`${endPoint}`);
  }

  logout() {
    this.events.eventStart({
      eventType: 'LOGOUT',
      triggerType: 'request'
    })
    // remove user from local storage to log user out
    this.localStorage.removeItem(LOCAL.CURRENT_USER);
    this.currentUserSubject.next(null);
    this.events.eventStart({
      eventType: 'LOGOUT_SUCCESS',
      triggerType: 'response'
    })
    this.router.navigate(['/login']);
  }

  public twtResetPin(params: { accounts: Array<Account> }) {
    return this.authTwt.doLogin(params);
  }

  public twtvalidatePin(params: { pinCode: number, msisdn: string }) {
    return this.authTwt.doPinCheck(params);
  }
}
