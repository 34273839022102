import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BehSubjectService {
  
  public loader$ = new BehaviorSubject<boolean>(true);
  public showSideNav$ = new BehaviorSubject<boolean>(false);
  public apiRequest$ = new BehaviorSubject<string>(null);
  public showTutorial$ = new BehaviorSubject<boolean>(false);
  public sub$ = new BehaviorSubject<boolean>(false);
  public carro$ = new BehaviorSubject<boolean>(false);
  public urlid$ = new BehaviorSubject<string>(null);
  public gameStarted$ = new BehaviorSubject<boolean>(false);

  constructor() {}
}
