// globals.ts
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { environment } from 'src/environments/environment';
import { BaseRequestBody } from '../models/auth.model';
import { LOCAL } from '../storage/local.enum';
import { LocalStorageService, SessionStorageService } from './_storage/storage.service';

declare let register: any;

@Injectable({ providedIn: 'root' })

export class GlobalsService {

  public readonly relativeUrl: string = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

  /*---------- PROJECT VARIABLES ----------*/
  public readonly serviceId: number = 155432;
  public readonly apiKey: string = '0';
  public readonly partnerId: number = 0;
  public readonly operatorId: number = 8;
  public readonly countryId: number = 55;
  public readonly productId: number = 55486;
  public readonly lang: string = 'en';
  public readonly multiLang: boolean = false;
  public readonly langsList = ['pt_BR']; /*-- ARRAY --*/
  public readonly contextKey: string = 'TestSoundAlgar';
  public readonly eventRegist = {
    timweBi: true,
    firebase: true
  }
  public readonly app = {
    title: 'App Title'
  }
  
  /*---------- PROJECT VARIABLES ----------*/

  constructor(
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService
  ) {
    // this.localStorage.setItem(LOCAL.DEVICE, this.generateDeviceId());
    // this.sessionStorage.setItem(LOCAL.DEVICE, this.generateSessionId());
  }

  public initPush(msisdn = null) {
    location.protocol == 'https:' && register(this.productId, msisdn, this.operatorId, this.countryId, this.serviceId);
  }

  
  public generateDeviceId() {
    
    let d = this.localStorage.getItem(LOCAL.DEVICE);
    if (d) {
      return d;
    } else {
      d = UUID.UUID();
      this.localStorage.setItem(LOCAL.DEVICE, d);
      return d;
    }
  }

  public generateSessionId() {

    let d = this.sessionStorage.getItem(LOCAL.SESSION);
    if (d) {
      return d;
    } else {
      d = UUID.UUID();
      this.sessionStorage.setItem(LOCAL.SESSION, d);
      return d;
    }
  }
  
  public bodyScroll(open) {
    open ? document.querySelector('body').classList.add('menu-open') : document.querySelector('body').classList.remove('menu-open');
  }
  
  public callHeaders(useJwt: boolean = true) {
    let httpOptions = new HttpHeaders().set('Content-Type', 'application/json');
    useJwt && this.userStorage().j && (httpOptions = httpOptions.set('jwt', this.userStorage().j));
    return httpOptions;
  }
  
  // added to xpapi login
  public requestPreset() {
    let requestBody: BaseRequestBody = {
      connectionType: this._connection() ? this._connection().effectiveType : undefined,
      lang: this.userStorage().l || undefined,
      deviceUuid: this.userStorage().d || undefined,
      userAgent: navigator.userAgent,
      referer: environment.production ? location.hostname : 'recargadasortealgar.com.br',
      url: environment.production ? location.href : 'recargadasortealgar.com.br',
      useReferer: environment.production ? false : true,
      timestamp: new Date().getTime(),
      transactionId: UUID.UUID(),
      context: {},
      additionalParameters: {}
    }
  
    return requestBody;
  }


  private siteName() {
    let params = new URLSearchParams(location.search);
    if (params.has('portal')) {
      localStorage.p = params.get('portal');
      return params.get('portal');
    } else if (localStorage.p) {
      return localStorage.p;
    } else {
      return location.hostname;
    }
  }

  public userStorage() {
    return localStorage.u ? JSON.parse(localStorage.u) : {};
  }
  
  public mapResponse(response) {
    return !response.inError && response.responseCode == '200' ? { ...response.responseData, ...{ additionalFields: response.additionalFields } } : null;
  }
  
  private _connection() {
    return navigator['connection'] || navigator['mozConnection'] || navigator['webkitConnection'] || undefined
  }
}
