import { NgModule } from '@angular/core';

import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './authentication/login/login.component';

/* Components */
import { AuthGuard } from './_shared/guards/auth.guard';
import { UnauthenticatedGuard } from './_shared/guards/unauth.guard';

const appRoutes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./base/base.module').then(m => m.BaseModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [UnauthenticatedGuard],
  },
  {
    path: '',
    component: LoginComponent,
    canActivate: [UnauthenticatedGuard],
  },
  {
    // if path not found redirect to 404 page
    path: '**',
    component: LoginComponent,
    canActivate: [UnauthenticatedGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
