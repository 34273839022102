import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import {TranslateService} from '@ngx-translate/core';
import { Observable } from 'rxjs';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(private cookieService: CookieService, private translate: TranslateService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let lang;
        lang = this.translate.currentLang ? this.translate.currentLang : 'en';
        if(!this.cookieService.get("device-id")){
            this.cookieService.set("device-id", this.generateUUID());
        }
        if(this.cookieService.get('session-cookie')){
            request = request.clone({
                setHeaders: {
                    "token": this.cookieService.get("session-cookie"),// + this.cookieService.get('session-cookie'),
                    "txId": this.generateUUID(),
                    "device-id": this.cookieService.get("device-id"),
                    "lang": lang
                }
            });
        }
        else{
            request = request.clone({
                setHeaders: {
                    "txId": this.generateUUID(),
                    "device-id": this.cookieService.get("device-id")
                }
            });
        }
        return next.handle(request);
    }

    generateUUID () { // Public Domain/MIT
        let d = new Date().getTime();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
            d += performance.now(); //use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }
}