import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { GlobalsService } from './globals.service';
import { paramsType } from '../models/events-params.model';
import { BehSubjectService } from './beh-subject.service';
import { LocalStorageService } from './_storage/storage.service';
import { LOCAL } from '../storage/local.enum';
import { AngularFireAnalytics } from '@angular/fire/analytics';

declare let gtag: any;

@Injectable({
  providedIn: 'root',
})
export class EventsService {

  urlid: string;

  constructor(
    private global: GlobalsService,
    private http: HttpClient,
    private beh: BehSubjectService,
    private localStorage: LocalStorageService,
    private analytics: AngularFireAnalytics
  ) { }
  todayDateInMilliSeconds = Date.now();

  public eventStart(params: paramsType) {

    this.beh.urlid$.subscribe(
      res => {
        this.urlid = res;
      }
    )

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    /*-- triggerType USED ONLY FOR ANALYTICS | no needed on body request call --*/
    params.eventCategory = params.triggerType;
    delete params.triggerType;

    params.sessionKey = params.sessionKey || this.global.generateSessionId() || undefined;
    params.deviceUuid = params.deviceUuid || this.global.generateDeviceId() || undefined;
    params.operatorId = this.global.operatorId;
    params.serviceId = params.serviceId || +this.global.serviceId || undefined;
    params.productId = params.productId || undefined;
    params.productName = params.productName || undefined;
    params.useSession = params.useSession || false;
    params.eventDateClient = this.todayDateInMilliSeconds;
    params.url = params.url || window.location.href;
    params.referer = params.referer || document.referrer;
    params.sitename = params.sitename || window.location.host;
    params.userAgent = params.userAgent || navigator.userAgent;
    params.trafficTypeId = params.trafficTypeId || this._trafficType(true);
    params.trafficTypeName = params.trafficTypeName || this._trafficType();
    params.tierId = params.tierId || this._tier(true);
    params.tierName = params.tierName || this._tier();
    params.msisdn = params.msisdn || (this.localStorage.getItem(LOCAL.CURRENT_USER) ? atob(this.localStorage.getItem(LOCAL.CURRENT_USER)) : undefined);
    params.connectionType = params.connectionType || this._connection() ? this._connection().type : undefined;
    params.connectionEffectiveType = params.connectionEffectiveType || this._connection() ? this._connection().effectiveType : undefined;
    params.subSource = params.subSource || undefined;
    params.utm_source = this.urlid || undefined;


    /*  Firebase Analytics */
    this.analytics.logEvent(params.eventType, {
      'sessionKey': params.sessionKey,
      'deviceUuid': params.deviceUuid,
      'operatorId': params.operatorId,
      'serviceId': params.serviceId,
      'useSession': params.useSession,
      'url': params.url,
      'referer': params.referer,
      'sitename': params.sitename,
      'userAgent': params.userAgent,
      'msisdn': params.msisdn,
      'eventDateClient': params.eventDateClient,
      'trafficTypeId': params.trafficTypeId,
      'trafficTypeName': params.trafficTypeName,
      'connectionType': params.connectionType,
      'connectionEffectiveType': params.connectionEffectiveType,
      'tierId': params.tierId,
      'tierName': params.tierName,
      'subSource': params.subSource,
      'utm_source': params.utm_source
    });

    if (this.global.eventRegist.timweBi && window.location.hostname) {
      this.http.post(`${this.global.relativeUrl}/events/${params.eventType}`, params, httpOptions).toPromise();
    }
  }

  /*-- LOOK FOR USER LOGIN FLOW (header enrichment = 3G, Pin Flow = WIFI, Dinamic link = FORWARD, Already loged in = RETURN) --*/
  private _trafficType(id = false) {
    let userSession = this.localStorage.getItem(LOCAL.TRAFFIC_TYPE)
    let traficsType: any = { 1: '3G', 2: 'WIFI', 3: 'FORWARD', 4: 'RETURN' };
    return userSession ? id ? +userSession : traficsType[userSession] : undefined;
  }

  private _tier(id = false) {
    let userSession = this.localStorage.getItem(LOCAL.TIER)
    let tier: any = { 0: 'FREE', 1: 'PREMIUM' };
    return userSession ? id ? +userSession : tier[userSession] : undefined;
  }

  private _connection() {
    return (navigator['connection'] || navigator['mozConnection'] || navigator['webkitConnection'] || undefined);
  }
}
