import { Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { Subscription, throwError } from 'rxjs';
import { GlobalsService } from 'src/app/_shared/services/globals.service';
import { finalize } from 'rxjs/operators';
import { AlertService } from 'src/app/_shared/services/alert/alert.service';
import { EventsService } from 'src/app/_shared/services/events.service';
import { BehSubjectService } from 'src/app/_shared/services/beh-subject.service';
import { LocalStorageService } from 'src/app/_shared/services/_storage/storage.service';
import { LOCAL } from 'src/app/_shared/storage/local.enum';
import { AuthenticationWebportalsService } from '../services/authentication-webportals.service';

@Component(
  {
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss']
  }
)

export class LoginComponent implements OnInit, OnDestroy {

  @ViewChild('userPin') userPin: any;
  private _apiRequest: string;
  public loader = false;
  public pinAvailable = false;
  public msisdnError: boolean = false;
  public pinError: boolean = false;
  private msisdn: string;
  public title;
  private subscriptions: Subscription[] = [];
  public pinSent: boolean = false;
  public readonly operatorConfig = {
    msisdnPrefix: '+55',
    msisdnMaxLength: 12,
    pinMaxLength: 4,
  }
  private msisdnMaskInput = ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  private pinMaskInput = [/\d/, /\d/, /\d/, /\d/];

  public showLanding: boolean = true;

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly globals: GlobalsService,
    private readonly alertService: AlertService,
    private readonly events: EventsService,
    private beh: BehSubjectService,
    private localStorage: LocalStorageService,
    private authtwt: AuthenticationWebportalsService
  ) {

    this.events.eventStart({ eventType: 'MSISDN_PAGE_ACCESS' });
  }

  public ngOnInit() {
    setTimeout(() => { this.showLanding = false }, 3000);

    this.beh.showSideNav$.next(false);
    this.subscriptions.push(this.beh.apiRequest$.subscribe((val) => { this._apiRequest = val; }));
    this.events.eventStart({ eventType: 'MSISDN_PAGE_LOADED' });
    this.title = this.globals.app.title;
    this.optin();
  }

  /* public submitMsisdn(msisdn: string): void {
    this.localStorage.setItem(LOCAL.TRAFFIC_TYPE, 2)

    this.loader = true;
    this.alertService.clear();
    this.msisdnError = false;

    this.events.eventStart({ eventType: 'MSISDN_SUBMITTED' });

    msisdn = msisdn.toString().replace('(', '').replace(')', '').replace('-', '').replace(' ', '');

    if (msisdn.length < 11 || !msisdn) {

      if (!msisdn) {
        this.events.eventStart({ eventType: 'MSISDN_EMPTY' });
      } else {
        this.events.eventStart({ eventType: 'MSISDN_ERROR', msisdn: msisdn, triggerType: 'response' });
      }
      this.loader = false;
      this.msisdnError = true;
      // this.alertService.loginError('Número de Telefone Inválido');
      return;
    }

    this.authenticationService.normalizeMsisdn(msisdn).pipe(
      finalize(() => {
        this.loader = false;
        this.msisdnError = false;
      })).subscribe((data) => {
        if (data['isMsisdnValid']) {

          this.msisdn = data['normalizedMsisdn'];

          this.authenticationService.resetPin(this.msisdn).pipe(
            finalize(() => { this.loader = false })).subscribe(response => {
              if (response && response.respStatus == 1) {

                this.pinAvailable = true;
                this.pinSent = true;
                this.events.eventStart({ eventType: 'MSISDN_SUCCESS', msisdn: this.msisdn, requestData: this._apiRequest, responseData: JSON.stringify(response), triggerType: 'response' });

                this.events.eventStart({ eventType: 'PIN_PAGE_ACCESS' });
                this.events.eventStart({ eventType: 'PIN_PAGE_LOADED' });
                setTimeout(() => {
                  this.events.eventStart({ eventType: 'PIN_FOCUS' });
                  this.userPin.nativeElement.focus();
                }, 1);
              } else {
                this.events.eventStart({ eventType: 'MSISDN_ERROR', msisdn: this.msisdn, requestData: this._apiRequest, responseData: JSON.stringify(response), triggerType: 'response' });
                // this.alertService.loginError('Número de Telefone Inválido');
                this.msisdnError = true;
              }
            }, error => {
              this.events.eventStart({ eventType: 'MSISDN_ERROR', msisdn: this.msisdn, requestData: this._apiRequest, responseData: JSON.stringify(error), triggerType: 'response' });
              // this.alertService.loginError('Erro na Verificação');
              this.msisdnError = true;
            });

        } else {
          this.events.eventStart({ eventType: 'MSISDN_ERROR', msisdn: this.msisdn, requestData: this._apiRequest, triggerType: 'response' });
          this.msisdnError = true;
          // this.alertService.loginError('Número de Telefone Inválido');
        }
      }, error => {
        this.events.eventStart({ eventType: 'MSISDN_ERROR', msisdn: this.msisdn, requestData: this._apiRequest, responseData: JSON.stringify(error), triggerType: 'response' });
        this.msisdnError = true;
        // this.alertService.loginError('Erro na Verificação');
      });
  } */

  /* public submitPin(pin: number): void {

    this.events.eventStart({ eventType: 'PIN_SUBMITED' });

    this.alertService.clear();
    this.loader = true;
    this.pinError = false;

    if (!pin) {
      this.events.eventStart({ eventType: 'PIN_EMPTY' });
      this.loader = false;
      this.pinError = true;
      this.alertService.loginError('Senha Vazia');
      return;
    }

    this.authenticationService.validatePin(this.msisdn, pin).pipe(finalize(() => { this.loader = false })).subscribe(response => {
      if (response && response.respStatus == 22) {
        this.events.eventStart({ eventType: 'PIN_SUBMIT_ERROR' });
        this.pinError = true;
        this.alertService.loginError('Demasiadas tentativas inválidas. Por favor aguarde');
      }
      if (response && response.validationStatus == 1) {
        this.events.eventStart({ eventType: 'PIN_SUBMIT_SUCCESS' });
        this.events.eventStart({ eventType: 'LOGIN_SUCCESS', msisdn: this.msisdn, requestData: this._apiRequest, responseData: JSON.stringify(response), triggerType: 'response' });
        this.localStorage.setItem(LOCAL.CURRENT_USER, btoa(this.msisdn))
        let u = { msisdn: this.msisdn };

        this.authenticationService.currentUserSubject.next(u);
        // this.globals.initPush(this.msisdn);

        // this.router.navigate(['/home/cupons/free']);
        this.router.navigate(['/home/scratch']);
      } else {
        this.events.eventStart({
          eventType: 'PIN_SUBMIT_ERROR'
        });
        this.pinError = true;
        // this.alertService.loginError('Senha Inválida');
      }
    }, error => {
      this.events.eventStart({ eventType: 'PIN_SUBMIT_ERROR' });
      this.pinError = true;
      // this.alertService.loginError('Senha Inválida');
    });
  } */

  eventRegister(event) {
    this.events.eventStart({ eventType: event });
  }

  resendPin() {
    this.webportalsResetPin(this.msisdn);
  }

  optin() {
    this.authtwt.optin().subscribe(
      resSuccess => {
        console.log(resSuccess);
      }, error => { }, () => { });
  }

  public webportalsResetPin(msisdn: string) {
    this.localStorage.setItem(LOCAL.TRAFFIC_TYPE, 2)

    this.loader = true;
    this.alertService.clear();
    this.msisdnError = false;

    this.events.eventStart({ eventType: 'MSISDN_SUBMITTED' });

    msisdn = msisdn.toString().replace('(', '').replace(')', '').replace('-', '').replace(' ', '');

    if (msisdn.length < 11 || !msisdn) {

      if (!msisdn) {
        this.events.eventStart({ eventType: 'MSISDN_EMPTY' });
      } else {
        this.events.eventStart({ eventType: 'MSISDN_ERROR', msisdn: msisdn, triggerType: 'response' });
      }
      this.loader = false;
      this.msisdnError = true;
      // this.alertService.loginError('Número de Telefone Inválido');
      return;
    }

    this.authenticationService.normalizeMsisdn(msisdn).pipe(finalize(() => {
      // this.loader = false;
      this.msisdnError = false;
    })).subscribe((data) => {
      if (data['isMsisdnValid']) {

        this.msisdn = data['normalizedMsisdn'];

        this.authenticationService.twtResetPin({ accounts: [{ identityType: 'MSISDN', identity: this.msisdn, msisdn: this.msisdn }] }).pipe(
          finalize(() => {
            this.loader = false;
          })).subscribe(response => {
            console.log(response);
            // if(response['responseCode'] == '200' ){

            if (response.responseCode == 'SUCCESS') {
              this.pinAvailable = true;
              this.pinSent = true;
              this.events.eventStart({ eventType: 'MSISDN_SUCCESS', msisdn: this.msisdn, requestData: this._apiRequest, responseData: JSON.stringify(response), triggerType: 'response' });

              this.events.eventStart({ eventType: 'PIN_PAGE_ACCESS' });
              this.events.eventStart({ eventType: 'PIN_PAGE_LOADED' });
              setTimeout(() => {
                this.events.eventStart({ eventType: 'PIN_FOCUS' });
                this.userPin.nativeElement.focus();
              }, 1);
            } else {
              this.events.eventStart({ eventType: 'MSISDN_ERROR', msisdn: this.msisdn, requestData: this._apiRequest, responseData: JSON.stringify(response), triggerType: 'response' });
              this.msisdnError = true;
              // this.alertService.loginError('Número de Telefone Inválido');
            }
            // } else {
            //   this.events.eventStart({
            //     eventType: 'MSISDN_ERROR',
            //     msisdn: this.msisdn,
            //     requestData: this._apiRequest,
            //     responseData: JSON.stringify(response),
            //     triggerType: 'response',
            //   });
            //   this.alertService.loginError('Número de Telefone Inválido');
            // }

          }, error => {
            this.events.eventStart({ eventType: 'MSISDN_ERROR', msisdn: this.msisdn, requestData: this._apiRequest, responseData: JSON.stringify(error), triggerType: 'response' });
            this.msisdnError = true;
            // this.alertService.loginError('Erro na Verificação');
          });
      } else {
        this.events.eventStart({ eventType: 'MSISDN_ERROR', msisdn: this.msisdn, requestData: this._apiRequest, triggerType: 'response' });
        this.msisdnError = true;
        // this.alertService.loginError('Número de Telefone Inválido');
      }
    }, error => {
      this.events.eventStart({ eventType: 'MSISDN_ERROR', msisdn: this.msisdn, requestData: this._apiRequest, responseData: JSON.stringify(error), triggerType: 'response' });
      this.msisdnError = true;
      // this.alertService.loginError('Erro na Verificação');
    });
  }

  public webportalsValidatePin(pin: number) {
    this.events.eventStart({ eventType: 'PIN_SUBMITED' });

    this.alertService.clear();
    this.loader = true;
    this.pinError = false;

    if (!pin) {
      this.events.eventStart({ eventType: 'PIN_EMPTY' });
      this.loader = false;
      this.pinError = true;
      // this.alertService.loginError('Senha Vazia');
      return;
    }

    this.authenticationService.twtvalidatePin({ msisdn: this.msisdn, pinCode: pin }).pipe(
      finalize(() => {
        this.loader = false;
      })).subscribe(response => {
        console.log(response);

        if (response.responseCode == "SUCCESS") {
          this.events.eventStart({ eventType: 'PIN_SUBMIT_SUCCESS' });
          this.events.eventStart({ eventType: 'LOGIN_SUCCESS', msisdn: this.msisdn, requestData: this._apiRequest, responseData: JSON.stringify(response), triggerType: 'response' });
          this.localStorage.setItem(LOCAL.CURRENT_USER, btoa(this.msisdn))
          let u = { msisdn: this.msisdn }

          this.authenticationService.currentUserSubject.next(u);
          // this.globals.initPush(this.msisdn);

          // this.router.navigate(['/home/cupons/free']);
          this.router.navigate(['/home/scratch']);
        } else {
          this.events.eventStart({ eventType: 'PIN_SUBMIT_ERROR' });
          this.pinError = true;
          // this.alertService.loginError('Senha Inválida');
        }

      }, error => {
        this.events.eventStart({ eventType: 'PIN_SUBMIT_ERROR' });
        this.pinError = true;
        // this.alertService.loginError('Senha Inválida');
      });
  }


  ngOnDestroy() {
    this.subscriptions.forEach(subsc => subsc.unsubscribe());
  }
}