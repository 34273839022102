import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class UnauthenticatedGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router
  ) {}

  public canActivate(): boolean {
    if (this.authenticationService.currentUserValue) {
      // this.router.navigate(['/home/cupons/free']);
      this.router.navigate(['/home/scratch']);

      return false;
    }
    return true;
  }

  public canActivateChild(): boolean {
    return this.canActivate();
  }

  public canLoad(): boolean {
    return this.canActivate();
  }
}
