import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LazyLoadImageModule} from 'ng-lazyload-image';
import { CookieService} from 'ngx-cookie-service';
import { RequestInterceptor } from './interceptors/request-interceptor';
import { AlertComponent } from './components/alert/alert.component';
import { TextMaskModule } from 'angular2-text-mask';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService, SessionStorageService } from './services/_storage/storage.service';
import { PhonePipe } from './pipes/phone.pipe';
// import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';
import { SearchDrawPipe } from './pipes/search-draw.pipe';
import { CallInterceptor } from './interceptors/call.interceptor';

@NgModule({
  declarations: [
  AlertComponent,
  PhonePipe,
  SearchDrawPipe,
],
  imports: [
    CommonModule, 
    FormsModule,
    ReactiveFormsModule, 
    LazyLoadImageModule,
    TextMaskModule,
    SlickCarouselModule,
    NgbModule,
    // AngularFireModule.initializeApp(environment.firebase),
    // AngularFireAnalyticsModule
  ],
  providers: [
    CookieService,
    LocalStorageService,
    SessionStorageService,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CallInterceptor, multi: true },
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    AlertComponent,
    TextMaskModule,
    SlickCarouselModule,
    NgbModule,
    PhonePipe,
    SearchDrawPipe
    // AngularFireModule,
    // AngularFireAnalyticsModule
  ]

})
export class SharedModule { }